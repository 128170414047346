import React, { useState, useEffect } from "react";
import history from "services/history";
import { version } from '../../../package.json';

import "./splash.scss";

const SplashComponent = (props) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            history.push("/tutorial");
        }, 2000);


        return () => clearInterval(timeout);
    }, []);

    return (
        <div className="splash-component">
            <img src={require("assets/splash.png")} alt="" />
            <div className="version">v.{version}</div>
        </div>
    );
};

export default SplashComponent;