import React, { useEffect } from 'react';
import SubmitNewJobComponent from '../components/SubmitNewJobComponent';
import { withRouter } from 'react-router-dom';
import { actions, EVENT_TYPES } from 'components/config/mixpanel';

const SubmitNewJob = (props) => {
    const { incidenceData } = props.location?.state;

    useEffect(() => {
        actions.pageView(EVENT_TYPES.SUBMIT_NEW_JOB_PAGE1);
    }, [])

    return <SubmitNewJobComponent
        {...props}
        {...{ incidenceData }}
    />
}

export default withRouter(SubmitNewJob);