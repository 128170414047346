import { IoMdPhotos, IoIosArrowBack } from "react-icons/io";
import React, { useState, useEffect } from "react";
import history from "services/history";
import Drawer from '../Drawer/Drawer'

import "./header.scss";

const HeaderComponent = (props) => {
    const { back, isPremium, onClickBack, hideMenu, children, skipButton } = props;

    return (
        <div className={`header-component ${isPremium && "isPremium"}`} >
            <div
                className="back center"
                onClick={onClickBack ? onClickBack : () => history.goBack()}
            >
                <IoIosArrowBack size="20px" />
                <span>Back</span>
            </div>
            {children ? children : <img src={require("assets/BEDBUGID.png")} alt="logo" />}
            <div className="drawer">
                {skipButton ? skipButton : !hideMenu && <Drawer />}
            </div>
        </div>
    );
};
export default HeaderComponent;
