import React, { useState, useEffect } from "react";
import FilterComponent from "../components/FilterComponent";
import { getPestControllersService } from "services/pestController";
import _ from "lodash";

const FilterContainer = (props) => {
    const { applyFilters, toggleModal, showModal, filters } = props;
    const [loading, setLoading] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [pestCoverages, setPestCoverages] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        getPestControllers();
    }, []);

    const getPestControllers = async () => {
        const data = await getPestControllersService();

        const cities = data
            .filter(pestController => pestController.city)
            .map(pestController => pestController.city);
        const citiesUnique = _.uniq(cities);
        const citiesOptions = citiesUnique.map((location, i) => {
            return {
                label: location,
                value: i
            }
        });

        const pestCoverages = data
            .filter(pestController => pestController.pestCoverage)
            .map(pestController => pestController.pestCoverage).flat(1);
        const pestCoveragesUnique = _.uniq(pestCoverages);
        const pestCoveragesOptions = pestCoveragesUnique.map((item, i) => {
            return {
                label: item,
                value: i
            }
        });

        const serviceOptions = data
            .filter(pestController => pestController.serviceOptions)
            .map(pestController => pestController.serviceOptions)
            .flat(1);

        const serviceOptionsUnique = _.uniq(serviceOptions);

        setCities(citiesOptions);
        setPestCoverages(pestCoveragesOptions);
        setServiceOptions(serviceOptionsUnique);
        setLoading(false);
    };

    return <FilterComponent
        loading={loading}
        cities={cities}
        pestCoverages={pestCoverages}
        serviceOptions={serviceOptions}
        applyFilters={applyFilters}
        toggleModal={toggleModal}
        showModal={showModal}
        filters={filters} />;
};

export default FilterContainer;
