import React, { useState, useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import './MostTrustedTerminator.scss';

const MostTrustedTerminator = () => {

    return (
        <div className="trusted-terminator-wrapper">
            <AiFillStar size={12} color='#fff' style={{padding: '2px'}} />
            <div className="trusted-text">
                Most trusted
            </div>
        </div>
    )
}

export default MostTrustedTerminator;