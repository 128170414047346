import customFetch from "./customFetch";
import { actions, EVENT_TYPES } from "components/config/mixpanel";

export const getUserCoordByZipcodeService = async (zipcode) => {
    try {
        let response = await customFetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode},US&key=AIzaSyBTBtSShkECf6YgHMLDgiJv1zMFiVtOE5U`, {
            method: "GET",
            hideHeaders: true
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err
    }
};

const rad = function (x) {
    return x * Math.PI / 180;
};

export const getDistanceBetweenTwoPoints = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d; // returns the distance in meter
};

export const getUserCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        const userLocation = JSON.parse(localStorage.getItem("location"));

        if (userLocation.setByZipcode) {
            resolve(userLocation);
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    const latlng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    resolve(latlng);
                },
                    function (error) {
                        // If error, i.e. denied access
                        reject("Error while trying to retrieve your location.");
                    });
            } else {
                reject("I'm sorry, but geolocation services are not supported by your browser.");
            }
        }

    })
}

export const rateTerminix = (rate) => {
    actions.sendEvent(EVENT_TYPES.CALL_TERMINIX, rate.mood);
}

export const callTerminix = () => {
    actions.sendEvent(EVENT_TYPES.CALL_TERMINIX);
}