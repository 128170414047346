import React, { useState, useEffect } from 'react';
import { updateIncidenceService } from 'services/camera';
import EmailToEntomologistComponent from '../components/EmailToEntomologistComponent';
import { withRouter } from "react-router-dom";

const EmailToEntomologist = (props) => {
    const incidenceData = props.location?.state?.incidenceData;
    console.log('incidenceData', incidenceData);

    const [loadingUpdate, setLoadingUpdate] = useState("");

    const updateIncidence = async (values) => {
        try {
            setLoadingUpdate(true);
            const res = await updateIncidenceService(incidenceData.idIncidence, values);
            setLoadingUpdate('done');
        }
        catch (e) {
            console.log('error updating', e);
            setLoadingUpdate(false);
        }
        finally {
        }
    }

    return <EmailToEntomologistComponent
        {...props}
        {...{ updateIncidence, loadingUpdate }}
    />

}

export default withRouter(EmailToEntomologist);