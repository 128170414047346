import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoIosClose, IoIosRefresh } from "react-icons/io";
import Select from "react-select";
import { Button } from "components/elements";
import CheckboxRow from 'components/elements/CheckboxRow/CheckboxRow';
import CheckboxRowSkeleton from 'components/elements/CheckboxRowSkeleton/CheckboxRowSkeleton';
import _ from 'lodash';
import "./filter.scss";

const FilterComponent = (props) => {
    const {
        showModal = true,
        toggleModal,
        cities,
        pestCoverages,
        serviceOptions,
        applyFilters,
        loading,
        filters
    } = props;

    const [selectedCity, setSelectedCity] = useState({});
    const [selectedPestCoverage, setSelectedPestCoverage] = useState({});
    const [selectedServiceOptions, setSelectedServiceOptions] = useState([]);
    const [selectedOrderBy, setSelectedOrderBy] = useState('');

    useEffect(() => {
        if (!_.isEmpty(filters)) {
            if (filters.city?.filterValue) setSelectedCity(filters.city?.filterValue);
            if (filters.pestCoverage?.filterValue) setSelectedPestCoverage(filters.pestCoverage?.filterValue);
            if (filters.serviceOptions?.filterValue?.length) setSelectedServiceOptions(filters.serviceOptions?.filterValue);
            if (filters.orderBy?.filterValue?.length) setSelectedOrderBy(filters.orderBy?.filterValue);
        }
    }, [filters]);

    const changeSelectedServiceOptions = (serviceOption) => {
        let newSelected = _.cloneDeep(selectedServiceOptions);
        const alreadySelectedServiceOption = newSelected.includes(serviceOption);

        if (alreadySelectedServiceOption) {
            newSelected = newSelected.filter((thisServiceOption) => thisServiceOption !== serviceOption);
        } else {
            newSelected.push(serviceOption);
        }

        setSelectedServiceOptions(newSelected);
    };


    const setFilters = (reset) => {
        if (reset) {
            setSelectedCity({});
            setSelectedPestCoverage({});
            setSelectedServiceOptions([]);
            setSelectedOrderBy('');
            applyFilters(null);
        } else {
            const filters = {
                city: {
                    filterLabel: 'City',
                    filterValue: selectedCity
                },
                pestCoverage: {
                    filterLabel: 'Pest coverage',
                    filterValue: selectedPestCoverage
                },
                serviceOptions: {
                    filterLabel: 'Service options',
                    filterValue: selectedServiceOptions
                },
                orderBy: {
                    filterLabel: 'Order by',
                    filterValue: selectedOrderBy
                }
            };
            applyFilters(filters);
        }

        toggleModal();
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={toggleModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}>
            <div className="filter-component">
                <div className="modal-header">
                    <h3>
                        <strong>Filters</strong>
                    </h3>

                    <div className="close" onClick={toggleModal}>
                        <IoIosClose />
                    </div>
                </div>

                <div className="modal-content">
                    <h3>Location</h3>
                    <Select
                        isClearable
                        options={cities}
                        placeholder="Select location"
                        value={_.isEmpty(selectedCity) ? null : selectedCity}
                        onChange={(selectedOption, inputOptions) => setSelectedCity(selectedOption)} />
                </div>

                <div className="modal-content">
                    <h3>Pest coverage</h3>
                    <Select
                        isClearable
                        options={pestCoverages}
                        placeholder="Select pest coverage"
                        value={_.isEmpty(selectedPestCoverage) ? null : selectedPestCoverage}
                        onChange={(selectedOption, inputOptions) => setSelectedPestCoverage(selectedOption)} />
                </div>

                <div className="modal-content">
                    <h3>Service options</h3>
                    {
                        loading ?
                            _.times(2, (i) => (
                                <CheckboxRowSkeleton key={i} />
                            )) :
                            serviceOptions.map((serviceOption, i) => {
                                return <CheckboxRow
                                    key={i}
                                    onClick={() => changeSelectedServiceOptions(serviceOption)}
                                    isChecked={selectedServiceOptions.includes(serviceOption)}
                                    label={serviceOption} />;
                            })
                    }
                </div>

                <div className="modal-content">
                    <h3>Order by</h3>

                    <div className="order-by-wrapper">
                        <OrderFilterTag
                            name="Pest Pro Name"
                            isSelected={selectedOrderBy === 'name'}
                            onClick={() => setSelectedOrderBy(selectedOrderBy === 'name' ? '' : 'name')} />

                        <OrderFilterTag
                            name="Rating"
                            isSelected={selectedOrderBy === 'stars'}
                            onClick={() => setSelectedOrderBy(selectedOrderBy === 'stars' ? '' : 'stars')} />
                    </div>
                </div>

                <div className="modal-bottom">
                    <div className="flex">
                        <Button type="text" onClick={() => setFilters(true)}>
                            <IoIosRefresh />
                            Reset
                        </Button>
                        <Button onClick={() => setFilters()}>Apply filters</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const OrderFilterTag = props => {
    const { name, isSelected, onClick } = props;

    return (
        <div onClick={onClick} className={isSelected ? "order-filter-tag active" : "order-filter-tag"}>
            <div>{name}</div>
        </div>
    )
}

const customStyles = {
    content: {
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        padding: 0,
    },
};

export default FilterComponent;
