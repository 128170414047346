import React, { useState, useEffect } from "react";
import { Header, MostTrustedTerminator, Search } from "components/elements/index";
import { IoIosStar, IoIosPin } from "react-icons/io";
import _ from "lodash";
import ReviewCard from "./ReviewCard";
import { Button } from "components/elements/index";
import { BsChatDots } from "react-icons/bs";
import "./pestControllerDetails.scss";
import { contactPestPro } from "utils/pestController";

const PestControllerDetailsComponent = (props) => {
    const {
        pestController: { name, city, country, pestCoverage, stars, reviews, urlProfileImage, description, specialitiesDescription, historyDescription, ownerDescription, lastName, phone, id, isPremium },
    } = props;


    return (
        <div className="pest-controller-details-conponent">
            <Header isPremium={isPremium} />

            {isPremium ? <div className="colored-background"></div> : null}

            <div className="content">
                {isPremium ? <div style={styles.premiumCover}>
                    <img alt="terminix-cover" src={require('assets/images/TerminixWhite.png')} resizeMode='contain' style={{ width: 250, }} />
                </div> : null}

                <div style={styles.card}>

                    <section className={`cover ${isPremium && "premium"} `} >
                        <img src={urlProfileImage || require("assets/user-placeholder.jpg")} alt="pest pro" />
                        <div className="name">
                            <h2>{name}</h2>
                            {isPremium && <MostTrustedTerminator />}
                        </div>
                    </section>

                    <section>
                        {city && country ? (
                            <div className="flex align-center">
                                <img src={require('assets/icons/location.svg')} alt="bug-icon" style={{ width: 20, height: 20, marginRight: 3 }} />
                                <h4 style={{ fontWeight: 600 }}>
                                    {city}, {country}
                                </h4>
                            </div>
                        ) : null}


                        {pestCoverage?.length > 0 && (
                            <>
                                <div className="flex align-center">
                                    <img src={require('assets/icons/bug.svg')} alt="bug-icon" style={{ width: 20, height: 20, marginRight: 3 }} />
                                    <h4 style={{ fontWeight: 600 }}>
                                        Expert in
                                    </h4>
                                </div>
                                <div className="skills">
                                    {pestCoverage.map((skill) => <div className="skill">
                                        <div>{skill}</div>
                                    </div>)}
                                </div>
                            </>
                        )}
                    </section>

                    <section>
                        <h3>Description</h3>
                        <p>{description}</p>
                        <p>{specialitiesDescription}</p>
                        <p>{historyDescription}</p>
                        <p>{ownerDescription}</p>
                    </section>

                    <section className="reviews-wrapper">
                        <div className=" flex align-center">
                            <h3>Reviews</h3>
                            <div className="reviews">
                                {stars?.toFixed(1) || "-"}
                                <IoIosStar />({reviews?.length})
                            </div>
                        </div>
                        {reviews?.length > 0 && reviews.map((review) => <ReviewCard review={review} />)}
                    </section>
                </div>
                <div className="floating-button">
                    <Button
                        style={isPremium ? { backgroundColor: '#080c22' } : {}}
                        anchor={isPremium ? "https://www.terminix.com/free-inspection/?interestarea=BED%20BUG&utm_source=bed-bug-id&utm_medium=organic" : null}
                        icon={isPremium ? <img src={require("assets/icons/PhoneOutgoing.svg")} alt="" /> : <BsChatDots size={18} />}
                        bold={false}
                        onClick={() => contactPestPro({
                            id,
                            name,
                            lastName,
                            phone
                        })}>
                        Schedule
                    </Button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    premiumCover: {
        backgroundColor: "#20aa4d",
        height: 150,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex"
    },
    card: {
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        marginTop: -20,
        backgroundColor: "#fff",
        padding: 20
    },
}

export default PestControllerDetailsComponent;
