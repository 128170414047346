import React, { useEffect } from 'react';
import Modal from 'react-modal';

const ModalLayout = ({ isOpen, toggleModal, children }) => {
    const customStyles = {
        overlay: {
            background: "#00000080",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            overflow: 'auto',
            borderRadius: '27px',
            maxHeight: '100%',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: 'none'
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            style={customStyles}
        >
            {children}
        </Modal>
    );
};

export default ModalLayout;