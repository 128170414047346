import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import history from "services/history";
import PestControllerDetailsComponent from "../components/PestControllerDetailsComponent";
import * as Mixpanel from 'components/config/mixpanel';
import { contactPestPro } from "utils/pestController";

const PestControllerDetailsContainer = (props) => {
    const chatRef = useRef(null);
    const pestController = props.location.state?.pestController;
    const { id, name, lastName, phone, urlProfileImage, country, city, pestCoverage, reviews, description } = pestController

    if (!pestController) {
        history.push({
            pathname: "/pest-controllers",
        });
    }

    useEffect(() => {
        Mixpanel.actions.pageView("PEST_PRO", {
            id,
            name,
            lastName,
            phone,
            picture: urlProfileImage,
            country,
            city,
            pestCoverage,
            reviews,
            description
        });
    }, [])


    return <PestControllerDetailsComponent pestController={pestController}  />;
};
export default withRouter(PestControllerDetailsContainer);
