import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "services/history";

//screens
import Camera from "components/Camera/containers/Camera";
import PestControllers from "components/PestControllers/containers/PestControllers";
import PestControllerDetails from "components/PestControllerDetails/containers/PestControllerDetails";
import Splash from "components/Splash/Splash";
import Tutorial from "components/Tutorial/Tutorial";
import Chat from "components/Chat/Chat";
import Feedback from "components/Feedback/containers/Feedback";
import Satisfaction from "components/Satisfaction/containers/Satisfaction";
import EmailToEntomologist from "components/EmailToEntomologist/containers/EmailToEntomologist";
import SubmitNewJob from "components/SubmitNewJob/containers/SubmitNewJob";
import SubmitNewJobArea from "components/SubmitNewJobArea/containers/SubmitNewJobArea";
import PestControllersResults from "components/PestControllersResults/containers/PestControllersResults";

function App() {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        let timesEnteredInTheApp = localStorage.getItem('timesEnteredInTheApp');
        if (timesEnteredInTheApp) timesEnteredInTheApp = parseInt(timesEnteredInTheApp);
        else timesEnteredInTheApp = 0;
        timesEnteredInTheApp++;

        localStorage.setItem('timesEnteredInTheApp', timesEnteredInTheApp.toString());

        if (timesEnteredInTheApp === 3) setShowPopup(true);
    }, [])

    return (
        <Router history={history}>
            <div className="App">
                {showPopup && <Satisfaction
                    toggleModal={() => setShowPopup(!showPopup)}
                />}
                <Switch>
                    <Route path="/" exact={true}>
                        <Splash />
                    </Route>
                    <Route path="/tutorial">
                        <Tutorial />
                    </Route>
                    <Route path="/camera">
                        <Camera />
                    </Route>
                    <Route path="/new-job">
                        <SubmitNewJob />
                    </Route>
                    <Route path="/new-job-area">
                        <SubmitNewJobArea />
                    </Route>
                    <Route path="/pest-controllers-result">
                        <PestControllersResults />
                    </Route>
                    <Route path="/submit-entomologist">
                        <EmailToEntomologist />
                    </Route>
                    <Route path="/pest-controllers">
                        <PestControllers />
                    </Route>
                    <Route path="/pest-controller-details">
                        <PestControllerDetails />
                    </Route>
                    <Route path="/chat">
                        <Chat />
                    </Route>
                    <Route path="/feedback">
                        <Feedback />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
