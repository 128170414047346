import React from "react";
import { IoIosStar, } from "react-icons/io";
import { BsChatDots } from "react-icons/bs";
import history from "services/history";
import { GrTextAlignFull } from 'react-icons/gr';
import { Button, MostTrustedTerminator } from "components/elements";
import "./pestCard.scss";
import { actions, EVENT_TYPES } from "components/config/mixpanel";

const PestCard = (props) => {
    const { pestController, contactPestController } = props;
    const { name, city, country, skills, pestCoverage, stars, reviews, urlProfileImage, isPremium, description } = pestController;

    const location = country && city ? `${country}, ${city}` : "-";
    const reviewsAverage = stars;

    return (
        <div className="pest-card-component" >
            <div className="card-info">
                <div className="card-photo-wrapper">
                    <img src={urlProfileImage ? urlProfileImage : require('assets/images/user-placeholder.jpg')} alt="" className="card-photo" />
                </div>

                <div className="card-content">
                    <div className="title-wrapper ">
                        <div className="name">
                            {name}
                        </div>
                        {!isPremium ?
                            <div className="reviews">
                                <div className="reviews-text">
                                    {(reviewsAverage && reviewsAverage.toFixed(1)) || '-'}
                                </div>
                                <div className="reviews-star">
                                    <IoIosStar size={12} color="#50E3C2" />
                                </div>
                                <div className="reviews-count">({reviews?.length})</div>
                            </div>
                            : <MostTrustedTerminator />
                        }
                    </div>

                    <div className="icon-text-wrapper">
                        <img src={require('assets/icons/location.svg')} alt="bug-icon" style={{ width: 20, height: 20, marginRight: 3 }} />
                        <div style={{ fontWeight: 600 }}>{location || "-"}</div>
                    </div>
                    {
                        pestCoverage?.length ?
                            <>
                                <div className="icon-text-wrapper" style={{ marginBottom: 0 }}>
                                    <img src={require('assets/icons/bug.svg')} alt="bug-icon" style={{ width: 20, height: 20, marginRight: 3 }} />
                                    <div style={{ fontWeight: 600 }}>Expert in</div>
                                </div>

                                <div className="coverage-wrapper">
                                    {
                                        pestCoverage.map((pestCoverageItem, i) => {
                                            return (
                                                <div key={i} className="badge-insect">
                                                    <div key={i} style={{ fontSize: 13 }}>
                                                        {pestCoverageItem}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {isPremium &&
                                    <div className="reviews">
                                        <div className="reviews-text" bold>
                                            {(reviewsAverage && reviewsAverage.toFixed(1)) || '-'}
                                        </div>
                                        <div className="reviews-star">
                                            <IoIosStar size={12} color="#50E3C2" />
                                        </div>
                                        <div className="reviews-count">({reviews?.length})</div>
                                    </div>
                                }
                            </>
                            : null
                    }
                </div>

            </div>
            {description && <div>
                <div secondary small style={{ paddingTop: 10, lineHeight: 20 }}>{description}</div>
            </div>}
            <div className="actions">
                <Button secondary
                    className="action-button"
                    icon={<GrTextAlignFull size={18} />}
                    onClick={() => {
                        history.push({
                            pathname: "/pest-controller-details",
                            state: { pestController: props.pestController },
                        });
                        actions.sendEvent(EVENT_TYPES.PEST_CONTROLLER_DETAILS, { name, isPremium });

                    }}
                >
                    Details
                </Button>
                <Button
                    className="action-button"
                    style={{ backgroundColor: '#080c22' }}
                    anchor={isPremium ? "https://www.terminix.com/free-inspection/?interestarea=BED%20BUG&utm_source=bed-bug-id&utm_medium=organic" : null}
                    icon={isPremium ? <img src={require("assets/icons/PhoneOutgoing.svg")} alt="" /> : <BsChatDots size={18} />}
                    bold={false}
                    onClick={() => !isPremium ? history.push('/submit-entomologist') : actions.sendEvent(EVENT_TYPES.CALL_TERMINIX)}
                >
                    {isPremium ? "Schedule" : "Contact"}
                </Button>
            </div>
        </div >
    );
};
export default PestCard;
