import React, { useState, useEffect } from "react";
import { IoMdSearch, IoIosOptions } from "react-icons/io";

import "./search.scss";

const SearchComponent = (props) => {
    const { onFilterClick } = props;
    return (
        <div className="search-component">
            <IoMdSearch size="24px" />
            <input type="text" {...props} />
            <div
                onClick={() => {
                    onFilterClick();
                }}
            >
                <IoIosOptions size="24px" />
            </div>
        </div>
    );
};
export default SearchComponent;
