import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CameraComponent from "../components/CameraComponent";
import { createIncidenceService, updateIncidenceService } from "services/camera";
import Modal from "react-modal";
import { Button } from "components/elements";
import { getUserCoordByZipcodeService } from "services/utils";
import * as Mixpanel from 'components/config/mixpanel';

const Camera = (props) => {
    const [location, setLocation] = useState({});
    const [enterZipCode, setEnterZipCode] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [incidenceData, setIncidenceData] = useState({});
    // const [incidenceData, setIncidenceData] = useState({
    //     "box": "137,188,285,334",
    //     "bug":  {
    //       "createdAt": null,
    //       "dangerLevel": "High",
    //       "info": `Adult bed bugs are small, brownish insects, just under 1/4' long and are relatively flat. Immature bed bugs (nymphs) resemble adults, but are much smaller and lighter in color. After feeding on a blood meal, the immature bed bugs may appear bright red in color. Bed bugs lack wings, and therefore they do not fly, but they are capable of moving swiftly on both horizontal and vertical surfaces. Bed bugs can mature in about 35 days. They then live about 7 to 12 months. This makes bed bug removal difficult without the help of a professional.`,
    //       "name": "Bed Bug",
    //       "new_label": "bedbug",
    //       "preventiveTreatments":  [
    //         "Bedbug infestations are best treated by professionals, but a few simple precautions can help you prevent future bed bug infestations in your home:",
    //         "1- Look for bed bugs under a cushion",
    //         "2- Inspect the luggage rack in your hotel room for bed bugs.",
    //         "3- Check secondhand furniture, beds and couches for any signs of bed bug infestation before bringing them home.",
    //       ],
    //       "urlImage": "https://extrasense.pitagorinesgroup.com//images/a921cb88-2d37-4460-88cc-b722ec337791.png",
    //     },
    //     "createdAt": "2021-10-11T13:57:37.000Z",
    //     "errorIA": false,
    //     "guestId": "d9a63bb3-da2e-4ec5-896f-b8f8c673f03d",
    //     "idIncidence": "9cb55acb-735c-41a8-b92b-505f9721eec6",
    //     "incidenceNumber": 1,
    //     "infoWeather":  {
    //       "base": "stations",
    //       "clouds":  {
    //         "all": 90,
    //       },
    //       "cod": 200,
    //       "dt": 1633960138,
    //       "mainWeather":  {
    //         "feels_like": 293.05,
    //         "humidity": 73,
    //         "pressure": 1018,
    //         "temp": 293.09,
    //         "temp_max": 293.09,
    //         "temp_min": 291.59,
    //       },
    //       "name": "Campinas",
    //       "sys": {
    //         "country": "BR",
    //         "sunrise": 1633941537,
    //         "sunset": 1633986629,
    //         "type": 1,
    //       },
    //       "timezone": -10800,
    //       "visibility": 10000,
    //       "weather": {
    //         "description": "overcast clouds",
    //         "icon": "04d",
    //         "id": 804,
    //         "main": "Clouds",
    //       },
    //       "wind": {
    //         "deg": 120,
    //         "speed": 6.17,
    //       },
    //     },
    //     "isDeleted": false,
    //     "lat": -22.883087158203125,
    //     "long": -47.04964762320856,
    //     "new_label": "bedbug",
    //     "os": "ios",
    //     "prob": 0.8193349838256836,
    //     "responseTime": 3290,
    //     "source": "gallery",
    //     "urlImage": "https://extrasense.pitagorinesgroup.com//images/bc61bf37-ef0a-4d44-b434-7885b701319b.jpeg",
    //     "userIP": "172.24.0.1",
    //   });
    const [modalManageImageOpen, setModalManageImageOpen] = useState(false);

    useEffect(() => {
        Mixpanel.actions.pageView("CAMERA");
        if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
            alert('You should use Safari Browser for this app to work properly.')
        }
    }, []);

    useEffect(() => {
        initializeLocation();
    }, [location]);

    const initializeLocation = () => {
        if (location.lat) {
            setShowModal(false);
            return;
        }
        let savedLoc = localStorage.getItem("location");
        if (savedLoc) {
            const parsed = JSON.parse(savedLoc);
            setLocation(parsed);
        } else {
            setShowModal(true);
        }
    };

    const closeModalShowZipCode = () => {
        setEnterZipCode(true);
    }

    const getUserLocation = () => {

        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.ALLOW_LOCATION);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const latlng = {
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                };

                setLocation(latlng);
                if (latlng) setShowModal(false);
                localStorage.setItem("location", JSON.stringify(latlng));
            },
                function (error) {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            alert("User denied the request for Geolocation.");
                            break;

                        case error.POSITION_UNAVAILABLE:
                            alert("Location information is unavailable.");
                            break;

                        case error.TIMEOUT:
                            alert("The request to get user location timed out.")
                            break;

                        case error.UNKNOWN_ERROR:
                            alert("An unknown error occurred.")
                            break;

                        default:
                            alert("Error while trying to retrieve your location.")
                    }
                    setEnterZipCode(true);
                });
        } else {
            alert("I'm sorry, but geolocation services are not supported by your browser.");
        }
    };

    const sendFile = async (file) => {
        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.UPLOAD_PICTURE)
        setLoading(true);
        const data = {
            lat: location.lat || 0,
            long: location.long || 0,
        };
        const response = await createIncidenceService(data, file);

        if (response["new_label"] === "too_small") {
            alert("The picture taken is too small. Please, try a different picture.");
        }
        else {
            setIncidenceData(response);

            Mixpanel.actions.pageView("RESULT", {
                picture: response.urlImage,
                dangerLevel: response?.bug?.dangerLevel,
                name: response?.bug?.name
            });
        }

        setLoading(false);
        setModalManageImageOpen(false);
    };

    const verifyZip = async () => {
        try {
            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.INSERT_ZIPCODE, { zipCode })
            setLoading(true);
            const { results } = await getUserCoordByZipcodeService(zipCode);
            if (results[0]) {
                const { lat, lng } = results[0].geometry.location;
                const latlng = {
                    lat,
                    lng,
                    setByZipcode: true
                };
                setLocation(latlng);
                localStorage.setItem("location", JSON.stringify(latlng));
                setLoading(false);
                setShowModal(false);
            } else {
                setLoading(false);
                alert("Zipcode not found. Please try again.")
            }
        } catch (e) {
            console.log("error", e);
            setLoading(false);
        }
    };

    const updateIncidence = async (values) => {
        try {
            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.SUBMIT_EMAIL_TO_ENTOMOLOGIST);
            setLoadingUpdate(true);
            const res = await updateIncidenceService(incidenceData.idIncidence, values);
            setLoadingUpdate('done');
        }
        catch (e) {
            console.log('error updating', e);
            setLoadingUpdate(false);
        }
        finally {
        }
    }

    return (
        <>

            <CameraComponent
                {...props}
                sendFile={sendFile}
                incidenceData={incidenceData}
                loading={loading}
                modalManageImageOpen={modalManageImageOpen}
                setModalManageImageOpen={setModalManageImageOpen}
                updateIncidence={updateIncidence}
                {...{ loadingUpdate, setIncidenceData, showModal, enterZipCode, setZipCode, verifyZip, getUserLocation, closeModalShowZipCode }}
            />
        </>
    );
};

export default withRouter(Camera);