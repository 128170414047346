import endpoints from "./endpoints";
import customFetch from "./customFetch";
import _ from "lodash";

export const sendFeedbackService = async ({ message, mood }) => {
    try {
        let response = await customFetch(endpoints.feedback, {
            method: "POST",
            bodyReq: {
                message,
                mood
            }
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
}
