import { Header } from 'components/elements';
import PestCard from 'components/PestControllers/components/PestCard';
import React, { useState, useEffect } from 'react';
import Lottie from "react-lottie";
import history from "services/history";
import './PestControllersResultsComponent.scss';
import animationData from 'assets/lottie/letter.json'

const PestControllersResultsComponent = (props) => {
    const { pestControllers } = props

    return (
        <div className="pest-controllers-result-wrapper">
            <Header
                showBackButton
                onClickBack={() => history.push("/camera")}
            />
            <div className="container">
                <div className="confirm-message-wrapper">
                    <Lottie options={defaultOptions}
                        height={100}
                        width={100} />
                    <div className="title">Thank you, below are the top service providers in your area.</div>
                </div>
                {pestControllers?.map((pest, i) => <PestCard pestController={pest} key={i} />)}
            </div>
        </div>
    )
}

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default PestControllersResultsComponent;