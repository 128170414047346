import React, { useState, useEffect } from "react";
import PestControllersComponent from "../components/PestControllersComponent";
import { getPestControllersService } from "services/pestController";
import { getDistanceBetweenTwoPoints, getUserCurrentLocation } from "services/utils";
import _ from 'lodash';

const PestControllersContainer = (props) => {
    const [pestControllers, setPestControllers] = useState([]);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        getPestControllers();
    }, []);

    const getPestControllers = async (filters) => {
        let latLongUser = {};

        try {
            latLongUser = await getUserCurrentLocation();
            let response = await getPestControllersService(filters);

            response = response.filter(pestPro => pestPro.postalCode && pestPro.active);

            response = response.map(pestPro => {
                const latLongPestPro = {
                    lat: pestPro.geoPoint.lat,
                    lng: pestPro.geoPoint.long
                }

                const distanceFromUser = getDistanceBetweenTwoPoints(latLongPestPro, latLongUser);

                return {
                    ...pestPro,
                    distanceFromUser
                };
            });

            if (filters?.orderBy?.filterValue?.length) {
                response = _.orderBy(response, (pestController) => {
                    const pestControllerField = pestController[filters.orderBy.filterValue];
                    return typeof pestControllerField === 'string' ? pestControllerField.toLowerCase() : pestControllerField
                }, ['asc']);
            } else {
                response = _.orderBy(response, ['isPremium', 'distanceFromUser'], ['asc'])
            }

            setPestControllers(response);
        } catch (err) {
            console.log(err);
        }
    };

    const applyFilters = (filters) => {
        setFilters(filters);
        getPestControllers(filters);
    };

    const removeFilterItem = (filterKey, filterValue) => {
        let newFilters = _.cloneDeep(filters);
        let newFiltersRowValue = newFilters[filterKey].filterValue;

        // Checkboxes filter
        if (Array.isArray(newFiltersRowValue)) {
            newFilters[filterKey].filterValue = newFiltersRowValue.filter(newFilterRowValue => newFilterRowValue !== filterValue);
        } else {
            // Select pickers filter
            if (typeof newFiltersRowValue === 'object') {
                newFilters[filterKey].filterValue = {};
            } else {
                // Order by option
                if (typeof newFiltersRowValue === 'string') {
                    newFilters[filterKey].filterValue = '';
                }
            }
        }

        setFilters(newFilters);
        getPestControllers(newFilters);
    }

    return <PestControllersComponent
        pestControllers={pestControllers}
        applyFilters={applyFilters}
        filters={filters}
        removeFilterItem={removeFilterItem} />;
};

export default PestControllersContainer;
