const BASE_URL = process.env.REACT_APP_API_URL || "https://extrasense.pitagorinesgroup.com/"; //DEV
// const BASE_URL = process.env.REACT_APP_API_URL || "https://prod.backend.extrasense.pitagorinesgroup.com/"; //PRO

const endpoints = {
    user: BASE_URL + "user",
    users: BASE_URL + "users",
    login: BASE_URL + "users/login",
    register: BASE_URL + "users/register",
    me: BASE_URL + "users/me",

    pestControllers: BASE_URL + "pest-controllers",
    pestControllerRequests: BASE_URL + "pest-controller-requests",
    bugs: BASE_URL + "bugs",
    skills: BASE_URL + "skills",

    incidences: BASE_URL + "incidences",
    predict: "http://beta-env.eba-ypmnysfp.us-east-1.elasticbeanstalk.com/predict",

    feedback: BASE_URL + "FeedBacks",
};

export default endpoints;
