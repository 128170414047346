import React, { useState, useEffect } from 'react';
import './SubmitNewJobComponent.scss';
import { Button, Header, ThreatLevel } from "components/elements";
import { useFormik } from "formik";
import * as Yup from "yup";
import RadioButtonCuston from 'components/elements/RadioButtonCuston/RadioButtonCuston';
import SquadButton from 'components/elements/SquadJobButton/SquadButton';
import history from "services/history";

const SubmitNewJobComponent = (props) => {
    const { incidenceData } = props
    const [alreadySelected, setAlreadySelected] = useState(false);

    const formik = useFormik({
        initialValues: {
            timeLine: {
                key: '',
                value: ''
            },
            dayPeriod: {
                key: '',
                value: ''
            }
        },
        enableReinitialize: false,
        validationSchema: Yup.object().shape({
            timeLine: Yup.object(),
            timeOfDay: Yup.object(),
        }),
        onSubmit: (values) =>
            history.push({
                pathname: "/new-job-area",
                state: { formValues: values, incidenceData: incidenceData },
            })
    });

    return (
        <div className="newJob-wrapper">
            <Header >
                <div className="title">Submit New job</div>
            </Header>
            <div className="content">
                <div className="basic-infos-wrapper">
                    <img src={incidenceData.urlImage} alt="Bug" className="photo-wrapper" />
                    <div className="info-details">
                        <div className="title">{incidenceData?.bug?.name}</div>
                        <ThreatLevel risk={incidenceData?.bug?.dangerLevel?.toLowerCase()} />
                    </div>
                </div>

                <div className="separator" />

                <div className='title' style={{ fontWeight: 'bold' }}>We need some additional information to find the right match.</div>
                <div className='subtitle'>We won't share this information until you decide to contact a pest professional</div>


                <div className="title">What’s your timeline?</div>

                <div className="selections">
                    {timeline.map((time) => {
                        return <RadioButtonCuston
                            style={{ marginBottom: 10 }}
                            checked={formik.values.timeLine.key === time.key}
                            onClick={() => formik.setFieldValue('timeLine', time)}
                            title={time.value} />
                    })}
                </div>

                <div className="title">Best time of the day?</div>
                <div className="selections" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {dayPeriod.map((period) => {
                        return <SquadButton
                            title={period.value} F
                            icon={period.icon}
                            iconChecked={period.iconChecked}
                            iconDisabled={period.iconDisabled}
                            checked={formik.values.dayPeriod.key === period.key}
                            onClick={() => {
                                formik.setFieldValue('dayPeriod', {
                                    key: period.key,
                                    value: period.value,
                                })
                                setAlreadySelected(true);
                            }}
                            hasASelection={alreadySelected}
                        />
                    })}

                </div>

                <div
                    style={{ paddingBottom: '30px' }}
                >
                    <Button
                        disabled={formik.values.timeLine.key === "" || formik.values.dayPeriod.key === ""}
                        onClick={formik.handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>

        </div >
    )
}

const timeline = [
    {
        key: 'soon',
        value: 'As soon as possible'
    },
    {
        key: 'nextWeek',
        value: 'In the next couple of weeks'
    },
    {
        key: 'nextMonth',
        value: 'Flexible over the next month'
    },
]

const dayPeriod = [
    {
        key: 'morning',
        value: 'Morning',
        icon: <img src={require("assets/icons/newJob/morning.png")} alt="Logo" />,
        iconChecked: <img src={require("assets/icons/newJob/morning-selected.png")} alt="Logo" />,
        iconDisabled: <img src={require("assets/icons/newJob/morning-disabled.png")} alt="Logo" />,
    },
    {
        key: 'afternoon',
        value: 'Afternoon',
        icon: <img src={require("assets/icons/newJob/afternoon.png")} alt="Logo" />,
        iconChecked: <img src={require("assets/icons/newJob/afternoon-selected.png")} alt="Logo" />,
        iconDisabled: <img src={require("assets/icons/newJob/afternoon-disabled.png")} alt="Logo" />,
    },
]

export default SubmitNewJobComponent;