import React, { useState, useEffect } from "react";
import { IoIosStar, IoIosPin } from "react-icons/io";

import "./reviewCard.scss";

const ReviewCardComponent = (props) => {
    const { review } = props;
    const { urlProfileImage, content, stars } = review;

    return (
        <div className="review-component">
            <div className="picture">
                <img src={urlProfileImage || require("assets/user-placeholder.jpg")} alt="pest pro" />
                <div className="flex align-center">
                    {stars} <IoIosStar />
                </div>
            </div>
            <div className="comment">{content}</div>
        </div>
    );
};
export default ReviewCardComponent;
