import React, { useState, useEffect, useRef } from "react";
import "./chat.scss";

const ChatComponent = (props) => {
    const chatRef = useRef(null);

    useEffect(() => {
        createChat();
    }, []);

    const createChat = () => {
        chatRef.current = window.Quiq({
            contactPoint: "bedbugid",
        });
        chatRef.current.toggle();
    };

    const forceOpen = () => {
        chatRef.current.toggle();
    };

    return (
        <div className="chat-component center flexColumn">
            <img src={require("assets/BEDBUGID.png")} alt="" className="logo" />
            <p>Opening your chat application...</p>

            <p className="reopen-chat" onClick={() => forceOpen()}>
                You should be redirected automatically. If not, <span className="blue"> click here</span>
            </p>
        </div>
    );
};
export default ChatComponent;
