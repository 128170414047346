import React, { useState, useEffect } from "react";
import SatisfactionComponent from "../components/SatisfactionComponent";
import _ from "lodash";
import { sendFeedbackService } from "services/feedback";

const SatisfactionContainer = (props) => {
    const { toggleModal } = props;
    const [loading, setLoading] = useState(false);

    const submit = async ({ mood, message }) => {
        try {
            setLoading(true);
            const res = await sendFeedbackService({ mood, message });
            toggleModal();
        }
        catch (e) {
            console.log('error send feedback', e);
        }
        finally {
            setLoading(false);
        }
    }

    return <SatisfactionComponent
        {...props}
        loading={loading}
        submit={submit}

    />;
};

export default SatisfactionContainer;
