import React, { useState, useEffect, useRef } from "react";
import { Button, ThreatLevel, Drawer, Header, CardButton, RecommendedExterminatorModal } from "components/elements/index";
import { withRouter } from "react-router-dom";
import history from "services/history";
import "./camera.scss";
import { actions, EVENT_TYPES } from "components/config/mixpanel";

const ResultScreenComponent = (props) => {
    const { updateIncidence, incidenceData, setPhoto, loadingUpdate, resetModalOptions } = props;

    const [showRecommendedExtModal, setShowRecommendedExtModal] = useState(false);
    const [allDescriptionVisible, setAllDescriptionVisible] = useState(false);
    const DESCRIPTION_LENGTH = 130;

    useEffect(() => {
        (() => {
            if (incidenceData?.bug?.info.length < DESCRIPTION_LENGTH) setAllDescriptionVisible(true);
        })()
    }, [incidenceData])

    const setDescriptionSize = (description) => {
        const newDescription = description.bug.info < DESCRIPTION_LENGTH ? description?.bug?.info : description?.bug?.info?.substring(0, DESCRIPTION_LENGTH);

        return `${newDescription}. `;
    }

    return (
        <div className="insect-detected">
            <Header onClickBack={() => resetModalOptions()} />
            <div className="identified-bug-image">
                <div className="threat-level">
                    <ThreatLevel risk={incidenceData?.bug?.dangerLevel?.toLowerCase()} />
                </div>
                <img src={incidenceData.urlImage} alt="bug" />
            </div>
            <div className="identified-bug-text">
                <div className="identified-bug-title">
                    <h2 style={{ marginRight: "auto" }}>
                        {incidenceData?.bug?.dangerLevel !== "NA" ? `It's highly likely a ` : null}
                        {incidenceData?.bug?.name}
                    </h2>
                </div>
                <div className="description">
                    {allDescriptionVisible ? incidenceData?.bug?.info : setDescriptionSize(incidenceData)}

                    {!allDescriptionVisible ? (
                        <span className="show-more-text" onClick={() => setAllDescriptionVisible(true)}>
                            Continue reading
                        </span>
                    ) : null}
                </div>

                <div className="buttons-wrapper">
                    <CardButton
                        style={{ margin: '0 20px' }}
                        onClick={() => {
                            setShowRecommendedExtModal(true)
                            actions.sendEvent(EVENT_TYPES.FIND_A_PEST_PRO_IN_YOUR_AREA);
                        }}
                        title="Find a Pest Pro in your area"
                    />

                    <div className="line-wrapper" />

                    <div
                        className="butons-wrap"
                        style={{ margin: '0 20px' }}
                    >
                        <CardButton
                            onClick={() => {
                                history.push({
                                    pathname: "/submit-entomologist",
                                    state: { incidenceData: incidenceData },
                                });
                                actions.sendEvent(EVENT_TYPES.SUBMIT_TO_ENTOMOLOGIST);

                            }}
                            secondary
                            title="Get your image reviewed by one of our experts"
                        />

                        <div className="line-wrapper" style={{ height: '3px' }} />

                        <div className="prevent-steps-wrapper" >
                            <div className="prevent-icon-wrapper" >
                                <img src={require("assets/icons/questionIcon.png")} alt="question icon" className="prevent-image" />
                                <span className="prevent-text">
                                    What should you do?
                                </span>
                            </div>

                            {incidenceData.bug?.preventiveTreatments.map(
                                (preventiveSteps, i) => <div
                                    key={i}
                                    className="step-text">
                                    {preventiveSteps}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <RecommendedExterminatorModal
                isOpen={showRecommendedExtModal}
                toggleModal={() => setShowRecommendedExtModal(false)}
                incidenceData={incidenceData}
                dangerLevel={incidenceData?.bug?.dangerLevel}
            />

        </div>
    )
}
export default withRouter(ResultScreenComponent);