import React, { useState, useEffect } from 'react';
import { getPestControllersService } from 'services/pestController';
import { getDistanceBetweenTwoPoints, getUserCurrentLocation } from 'services/utils';
import PestControllersResultsComponent from '../components/PestControllersResultsComponent';
import { actions, EVENT_TYPES } from "components/config/mixpanel";
import _ from 'lodash';

const PestControllersResults = (props) => {
    const [pestControllers, setPestControllers] = useState(null);

    useEffect(() => {
        actions.pageView(EVENT_TYPES.NEW_JOB_PEST_CONTROLLERS_RESULTS);
    }, [])

    const getPestControllers = async (filters) => {
        let latLongUser = {};

        try {
            latLongUser = await getUserCurrentLocation();
            let response = await getPestControllersService({});

            response = response.filter(pestPro => pestPro.postalCode && pestPro.active);

            response = response.map(pestPro => {
                const latLongPestPro = {
                    lat: pestPro.geoPoint.lat,
                    lng: pestPro.geoPoint.long
                }

                const distanceFromUser = getDistanceBetweenTwoPoints(latLongPestPro, latLongUser);

                return {
                    ...pestPro,
                    distanceFromUser
                };
            });

            if (filters?.orderBy?.filterValue?.length) {
                response = _.orderBy(response, (pestController) => {
                    const pestControllerField = pestController[filters.orderBy.filterValue];
                    return typeof pestControllerField === 'string' ? pestControllerField.toLowerCase() : pestControllerField
                }, ['asc']);
            } else {
                response = _.orderBy(response, ['isPremium', 'distanceFromUser'], ['asc'])
            }

            setPestControllers(response);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getPestControllers();
    }, [])

    return <PestControllersResultsComponent {...props} {...{ pestControllers }} />

}

export default PestControllersResults;