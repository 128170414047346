import { Button, Header } from 'components/elements';
import RadioButtonCuston from 'components/elements/RadioButtonCuston/RadioButtonCuston';
import SquadButton from 'components/elements/SquadJobButton/SquadButton';
import { useFormik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import Lottie from "react-lottie";
import animationData from 'assets/loading.json'
import * as Yup from "yup";
import { FaLocationArrow } from "react-icons/fa";
import './SubmitNewJobAreaComponent.scss';
import { Slider } from '@material-ui/core';

const SubmitNewJobAreaComponent = (props) => {
    const { submit, latLng, getLocation, isLoading, loadingLocation } = props
    const [alreadySelected, setAlreadySelected] = useState(false);

    const formik = useFormik({
        initialValues: {
            location: latLng.lat ? "My location" : "",
            homeSize: 0,
            homeType: "",
            features: {
                kidFriendly: false,
                petFriendly: false,
                environmentalFriendly: false,
            },
        },
        enableReinitialize: false,
        validationSchema: Yup.object().shape({
            location: Yup.string(),
            homeSize: Yup.number(),
            homeType: Yup.string(),
            features: Yup.object(),
        }),
        onSubmit: (values) => submit(values)
    });
    const setLocation = useMemo(() => {
        if (latLng) formik.setFieldValue('location', 'My location')
    }, [latLng])

    return (
        <div className="new-job-area-wrapper">
            <Header
                hideMenu
                skipButton={<div className="skip-button" onClick={() => submit({})}>Skip</div>}
            >
                <div className='title'>Submit New Job</div>
            </Header>

            <div className='content-wrapper'>
                <div className="subtitle">Location</div>
                <input
                    className='location-input'
                    value={formik.values.location}
                    onChange={(e) => { formik.setFieldValue('location', e.target.value) }}
                    placeholder="Write your location"
                />

                <div className="location-wrapper" onClick={() => getLocation()}>
                    <FaLocationArrow size={10} className="invert-icon" />
                    <div className="use-location" >Use current location</div>
                    <div className="loading-lottie">
                        {loadingLocation ? <Lottie options={defaultOptions}
                            height={20}
                            width={20} /> : null}
                    </div>
                </div>

                <div className="subtitle">Home size</div>
                <div className="slider">
                    <Slider
                        max={7000}
                        size="small"
                        value={formik.values.homeSize}
                        onChange={(_, value, __) => formik.setFieldValue('homeSize', value)}
                        valueLabelDisplay="on"
                        step={formik.values.homeSize > 3500 ? 100 : 50}
                    />
                    <div className="min-max-ft">
                        <div className="">0ft</div>
                        <div className="">7000ft+</div>
                    </div>
                </div>

                <div className="subtitle">Best time of the day?</div>
                <div className="selections" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {homeTypes.map((type, i) => {
                        return <SquadButton
                            title={type.value}
                            icon={type.icon}
                            iconChecked={type.iconChecked}
                            iconDisabled={type.iconDisabled}
                            style={i === (homeTypes.length - 1) ? { marginTop: 15 } : {}}
                            checked={formik.values.homeType === type.key}
                            onClick={() => {
                                formik.setFieldValue('homeType', type.key)
                                setAlreadySelected(true);
                            }}
                            hasASelection={alreadySelected}
                        />
                    })}
                </div>
                <div className="subtitle">Additional features</div>

                <div className="selections" >
                    {AditionalFeatures.map((feat) => {
                        return <RadioButtonCuston
                            style={{ marginBottom: 10 }}
                            checkbox
                            checked={formik.values.features[`${feat.key}`]}
                            onClick={() => formik.setFieldValue(`features.${feat.key}`, !formik.values.features[`${feat.key}`])}
                            title={feat.value} />
                    })}
                </div>

                <div style={{ paddingBottom: 30 }} >
                    <Button
                        loading={isLoading}
                        onClick={formik.handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    )
}


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const AditionalFeatures = [
    {
        key: 'kidFriendly',
        value: 'Kid friendly'
    },
    {
        key: 'petFriendly',
        value: 'Pet friendly'
    },
    {
        key: 'environmentalFriendly',
        value: 'Environmental friendly'
    },
]

const homeTypes = [
    {
        key: 'singleFamily',
        value: 'Single Family',
        icon: <img src={require('assets/icons/newJob/single-family.png')} alt="Logo" />,
        iconChecked: <img src={require('assets/icons/newJob/single-family-selected.png')} alt="Logo" />,
        iconDisabled: <img src={require('assets/icons/newJob/single-family-disabled.png')} alt="Logo" />,
    },
    {
        key: 'multiFamily',
        value: 'Multi-family',
        icon: <img src={require('assets/icons/newJob/multi-family.png')} alt="Logo" />,
        iconChecked: <img src={require('assets/icons/newJob/multi-family-selected.png')} alt="Logo" />,
        iconDisabled: <img src={require('assets/icons/newJob/multi-family-disabled.png')} alt="Logo" />,
    },
    {
        key: 'apartment',
        value: 'Apartment',
        icon: <img src={require('assets/icons/newJob/apartment.png')} alt="Logo" />,
        iconChecked: <img src={require('assets/icons/newJob/apartment-selected.png')} alt="Logo" />,
        iconDisabled: <img src={require('assets/icons/newJob/apartment-disabled.png')} alt="Logo" />,
    },
]


export default SubmitNewJobAreaComponent;