import React, { useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer';
import Button from '../button//Button';
import { GiHamburgerMenu } from "react-icons/gi";
import { RiFeedbackLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import './drawer.scss';
import * as Mixpanel from 'components/config/mixpanel';

const DrawerComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className='drawer-component'>
            <div onClick={() => {
                Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.OPEN_BURGER)
                setIsOpen(true)
            }}>
                <GiHamburgerMenu size={22} />
            </div>
            <Drawer anchor={"right"} open={isOpen} onClose={() => setIsOpen(false)}>
                <div className='drawer-content'>

                    <img className='logo' src={require("assets/BEDBUGID.png")} alt="logo" />

                    <Link to="/feedback" className="item" >
                        <RiFeedbackLine size={20} />
                        Feedback
                    </Link>

                    <div className="item">
                        <div style={{ width: '100%' }}>
                            <div className="download">Get our free app.</div>
                            <Button
                                anchor="https://onelink.to/bedbugid"
                                className="small">
                                Download now
                            </Button>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
export default DrawerComponent;