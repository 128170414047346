import React, { useState, useEffect } from 'react';
import { FaCheck } from "react-icons/fa";
import './RadioButtonCuston.scss';

const RadioButtonCuston = (props) => {
    const { checked, onClick, title, checkbox, className } = props

    return (
        <div className={`radio-button-custon-wrapper ${className && className} ${checkbox && 'checkbox-icon'} ${checked && 'checked'}`} onClick={onClick}>
            {checked
                ? <div className={`checked-icon ${checkbox && 'checkbox-icon'}`}>
                    <FaCheck />
                </div>
                : <div className={`unchecked-icon ${checkbox && 'checkbox-icon'}`} />}
            <div style={{ marginLeft: 8 }}>{title}</div>
        </div>
    )
}

export default RadioButtonCuston;