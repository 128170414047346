import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./CardButton.scss";
import { IoIosArrowForward } from "react-icons/io";

const CardButton = (props) => {
    const { children, style, secondary, link, onClick, params, title, subtitle } = props

    if (link) {
        return (
            <Link to={link} params={params} style={{ textDecoration: 'none' }}>
                <div className={`button-wrapper ${secondary && 'secondary'}`} style={style} onClick={onClick}>
                    <div className="image-wrapper" style={{ backgroundColor: secondary ? '#085F4B15' : '#50E3C231' }}>
                        <img src={require(secondary ? "assets/icons/iconButton.png" : "assets/icons/white-icon.png")} alt="icon" />
                    </div>
                    <div className="text-wrapper">
                        <div className="title">
                            {title}
                        </div>
                        <div className="subtitle">
                            {subtitle}
                        </div>
                    </div>

                    <IoIosArrowForward size={30} style={{ padding: '5px' }} color={secondary ? '#737581' : "#fff"} />
                </div>
            </Link>
        );
    }
    return (
        <div className={`button-wrapper ${secondary && 'secondary'}`} style={style} onClick={onClick}>
            <div className="image-wrapper" style={{ backgroundColor: !secondary ? '#085F4B15' : '#50E3C231' }}>
                <img src={require(secondary ? "assets/icons/iconButton.png" : "assets/icons/white-icon.png")} alt="icon" />
            </div>
            <div className="text-wrapper">
                <div className="text-wrapper">
                    <div className="title">
                        {title}
                    </div>
                    <div className="subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>

            <IoIosArrowForward size={30} style={{ padding: '5px' }} color={secondary ? '#737581' : "#080C22"} />
        </div>
    )
}

export default CardButton;