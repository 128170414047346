import React from "react";

const CheckBoxRowSkeleton = () => {
    return (
        <div style={{ flexDirection: 'row', alignItems: 'center', height: 50 }}>
            <div style={{ width: 170, height: 15, backgroundColor: '#eaeaea', borderRadius: 5 }}></div>
            <div style={{ width: 20, height: 20, backgroundColor: '#eaeaea', marginLeft: 'auto', borderRadius: 5 }}></div>
        </div>
    )
}

export default CheckBoxRowSkeleton;