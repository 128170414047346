import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoIosClose, IoIosRefresh } from "react-icons/io";
import { Button } from "components/elements";
import { MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentVerySatisfied } from 'react-icons/md';
import _ from 'lodash';
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Satisfaction.scss";

const SatisfactionComponent = (props) => {
    const {
        showModal = true,
        toggleModal,
        submit,
        loading
    } = props;

    const formik = useFormik({
        initialValues: {
            mood: "",
            message: "",
        },
        validationSchema: Yup.object().shape({
            mood: Yup.string().required("Please select one of the faces above."),
            message: Yup.string(),
        }),
        onSubmit: (values) => {
            submit(values);
        },
    });


    const getFeedbackMessage = () => {
        switch (formik.values.mood) {
            case "dissatisfied":
                return "We are sorry to hear that. What can we do better?";

            case "neutral":
                return "Tell us how we can improve your experience.";

            case "satisfied":
                return "Thank you! Do you have any comments?";

            default:
                return ""
        }
    }


    return (
        <div className="filter-component">
            <div className="modal-header">
                <h3>
                    <strong>Rate us</strong>
                </h3>

                <div className="close" onClick={toggleModal}>
                    <IoIosClose />
                </div>
            </div>

            <div className="modal-content">
                <h3>Please, rate your experience with us</h3>
            </div>

            <div className="modal-content">
                <div className="flex center">

                    <div className="mood" onClick={() => formik.setFieldValue('mood', 'dissatisfied')} >
                        <MdSentimentDissatisfied style={{ color: formik.values.mood === "dissatisfied" ? "#ed4300" : "gray" }} />
                    </div>
                    <div className="mood" onClick={() => formik.setFieldValue('mood', 'neutral')} >
                        <MdSentimentNeutral style={{ color: formik.values.mood === "neutral" ? "#FFEB3B" : "gray" }} />
                    </div>
                    <div className="mood" onClick={() => formik.setFieldValue('mood', 'satisfied')} >
                        <MdSentimentVerySatisfied style={{ color: formik.values.mood === "satisfied" ? "#50E3C2" : "gray" }} />
                    </div>
                </div>
            </div>

            {formik.errors.mood ? <p className="textCenter error" style={{ marginTop: 10 }} >{formik.errors.mood}</p> : null}

            {formik.values.mood ? <div className="inputWrapper">
                <p style={{ textAlign: 'center' }}>
                    {getFeedbackMessage()}
                </p>
                <textarea
                    multiline
                    placeholder={"Leave your comments here.\nIf you want us to reach out you personally, please share your email in the text box."}
                    value={formik.values.message}
                    onChange={(text) => formik.setFieldValue('message', text.target.value)}
                />
            </div> : null}

            <div className="modal-bottom">
                <div className="flex">
                    <Button onClick={formik.handleSubmit} loading={loading}>Submit</Button>
                </div>
            </div>
        </div>
    );
};

export default SatisfactionComponent;
