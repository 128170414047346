import React, { useState, useEffect } from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import './SquadButton.scss';

const SquadButton = (props) => {
    const { checked, onClick, title, className, iconChecked, icon, style, hasASelection, iconDisabled } = props

    return (
        <div className={`squad-button-wrapper ${className || ''} ${checked && 'checked'}`} style={style} onClick={onClick}>
            {checked ? iconChecked : hasASelection ? iconDisabled : icon}
            <div style={{ marginLeft: 8 }}>{title}</div>
            {checked && <AiFillCheckCircle  className="checkedIcon" />}
        </div>
    )
}

export default SquadButton;