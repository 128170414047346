import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { pestControllerRequestsService } from 'services/pestController';
import { getUserCoordByZipcodeService, getUserCurrentLocation } from 'services/utils';
import SubmitNewJobAreaComponent from '../components/SubmitNewJobAreaComponent';
import history from "services/history";
import _ from "lodash";
import { actions, EVENT_TYPES } from 'components/config/mixpanel';

const SubmitNewJobArea = (props) => {
    const { formValues, incidenceData } = props.location?.state;
    const [isLoading, setIsLoading] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);
    const [latLng, setLatLng] = useState(false);

    useEffect(() => {
        actions.pageView(EVENT_TYPES.SUBMIT_NEW_JOB_PAGE2);
    }, [])

    const getLocation = async () => {
        try {
            setLoadingLocation(true);
            const resp = await getUserCurrentLocation()
            setLatLng(resp);
        }
        catch (e) {
            console.log('error updating', e);
        }
        finally {
            setLoadingLocation(false)
        }
    }


    const submit = async (values) => {
        setIsLoading(true);
        let features = values?.features && Object.keys(values?.features).map((item) => { if (values?.features[`${item}`]) return item }).filter((item) => { return item !== undefined });
        let location = latLng || {
            lat: incidenceData.lat,
            lng: incidenceData.long
        };
        if (values?.location && values?.location !== "My location") {
            const googleLocationResults = await getUserCoordByZipcodeService(values?.location);
            location = googleLocationResults?.results?.[0]?.geometry?.location
        }

        try {
            const data = {
                timeline: [
                    formValues?.timeLine.key
                ],
                timeOfDay: [
                    formValues?.dayPeriod.key
                ],
                location: location || {},
                homeSize: values.homeSize ? values?.homeSize.toString() : '',
                homeType: values?.homeType || '',
                extraServices: features?.length > 0 ? features : [],
            }

            actions.sendEvent(EVENT_TYPES.SUBMIT_NEW_JOB, data);

            if (_.isEmpty(values)) {
                actions.sendEvent(EVENT_TYPES.SKIP_SUBMIT_NEW_JOB_PAGE2);
            }

            let resp = await pestControllerRequestsService(data)


            if (resp) {
                history.push("/pest-controllers-result");
            }
        }
        catch (e) {
            console.log('error updating submit newJobArea', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    return <SubmitNewJobAreaComponent {...props} {...{ submit, getLocation, latLng, isLoading, loadingLocation }} />

}

export default withRouter(SubmitNewJobArea);