import React, { useState, useEffect, useRef } from "react";
import { Camera } from "react-camera-pro";
import { IoMdPhotos, IoIosArrowBack, IoIosClose } from "react-icons/io";
import { AiOutlineReload, AiOutlineUnorderedList } from "react-icons/ai";
import { Button, ThreatLevel, Drawer } from "components/elements/index";
import { getImageLightness, getCroppedImg } from "services/camera";
import Lottie from "react-lottie";
import Jimp from "jimp";
import ResultScreen from './ResultScreen';
import animationData from 'assets/lottie/loading-white.json'
import Modal from "react-modal";
import ReactCrop from "react-image-crop";
import * as _ from 'lodash';
import * as Mixpanel from 'components/config/mixpanel';
import CameraTips from './CameraTips';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import "react-image-crop/dist/ReactCrop.css";
import "react-html5-camera-photo/build/css/index.css";
import "./camera.scss";

const mockIncidence = {
    box: "16,38,222,238",
    bug: { new_label: "tick", name: "Tick", },
    dangerLevel: "Medium",
    info: "A tick's color varies by species as ticks are difficult to identify to species without expert help. Adults can be smaller than a sunflower seed (over 1 cm long if engorged with blood), while tick larvae can be less than 1 mm.  These pests do not present much of a threat to well-built homes, but different species of ticks can transmit over 15 viruses and infections.",
    name: "Tick",
    new_label: "tick",
    preventiveTreatments: [""],
    urlImage: "https://extrasense.pitagorinesgroup.com//images/d5b75b61-1529-4fca-9509-99aa3852861b.png",
    createdAt: "2021-07-06T16:10:39.675Z",
    idIncidence: "39b7e2ba-a866-44c7-88f8-6aa1b204d475",
    lat: 29.3671025,
    long: 0,
    os: "webapp-Win32",
    prob: 0.9999996423721313,
    responseTime: 3724,
    urlImage: "https://extrasense.pitagorinesgroup.com//images/2c3b2e16-c464-44c5-aff5-5a21bf8fdacd.jpeg",
}

const CameraComponent = (props) => {
    const { sendFile, loading, incidenceData, modalManageImageOpen, setModalManageImageOpen, history, loadingUpdate, updateIncidence,
        setIncidenceData, showModal, enterZipCode, closeModalShowZipCode, getUserLocation, verifyZip, setZipCode } = props;
    // let incidenceData = mockIncidence;
    const [photo, setPhoto] = useState(null);
    const [showPictureConfirmation, setShowPictureConfirmation] = useState(false);
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const [uploadedCroppedPhoto, setUploadedCroppedPhoto] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tooDark, setTooDark] = useState(false);
    const [tooBright, setTooBright] = useState(false);
    const [imageCropProperties, setImageCropProperties] = useState({ aspect: 1 });
    const [inputFileKey, setInputFileKey] = useState(Math.random().toString(36));
    const showLoading = (photo || uploadedCroppedPhoto) && (isLoading || loading);
    // const showLoading =true;
    const showResult = (photo || uploadedCroppedPhoto) && incidenceData?.bug;
    const [pictureUploaded, setPictureUploaded] = useState(photo || uploadedCroppedPhoto || uploadedPhoto);
    const [bottomSheetOpen, setBottomSheetOpen] = useState(true);
    const [tooSmallCrop, setTooSmallCrop] = useState(null);

    useEffect(() => {
        const timeout = setInterval(() => {
            checkBrightness();
        }, 1000);

        return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
        setPictureUploaded(photo);
    }, [photo]);

    useEffect(() => {
        setPictureUploaded(uploadedPhoto);
    }, [uploadedPhoto]);

    useEffect(() => {
        setPictureUploaded(uploadedCroppedPhoto);
    }, [uploadedCroppedPhoto]);

    useEffect(() => {
        if (imageCropProperties.width < 200 && imageCropProperties.height < 200) setTooSmallCrop(true);
        else setTooSmallCrop(false);
    }, [imageCropProperties]);


    const camera = useRef(null);
    const imageRef = useRef(null);

    const checkBrightness = () => {
        try {
            if (camera.current && camera.current.takePhoto) {
                const base64 = camera.current.takePhoto();
                getImageLightness(base64, (brightness) => setBrightnessMessage(brightness));
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const setBrightnessMessage = (brightness) => {
        if (brightness <= 50) {
            setTooDark(true);
            setTooBright(false);
        } else if (brightness >= 200) {
            setTooBright(true);
            setTooDark(false);
        } else {
            setTooBright(false);
            setTooDark(false);
        }
    };

    const handleTakePhoto = async () => {
        setPhoto(null);
        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.TAKE_PICTURE);

        const base64 = camera.current.takePhoto();
        setIsLoading(true);

        const imageWidth = window.innerWidth;
        const imageHeight = window.innerHeight;

        const originY = imageHeight / 2 - 210 / 2 - 50; // 50 = Half of margin bottom due to options bar
        const originX = imageWidth / 2 - 210 / 2;

        try {
            const imageJimp = await Jimp.read(base64);
            console.log('imageJimp', imageJimp);
            const imageResized = imageJimp.resize(imageWidth, imageHeight);
            const imageCropped = imageResized.crop(originX, originY, 210, 210);

            const imageResizedTo200 = imageCropped.resize(250, 250);
            imageResizedTo200.getBase64(Jimp.AUTO, (err, res) => {
                if (err) {
                    alert(err);
                } else {
                    setPhoto(res);
                    setIsLoading(false);
                    setShowPictureConfirmation(true);
                }
            });
        } catch (err) {
            alert(err);
        }
    };

    const handleFileUpload = (event) => {
        setIsLoading(true);
        const file = event.target.files[0];
        const fileURL = URL.createObjectURL(file);

        setUploadedPhoto(fileURL);
        removeLoading();
        setModalManageImageOpen(true);
    };

    const onImageLoaded = (image) => {
        imageRef.current = image;
    };

    const removeLoading = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    };

    const onCropComplete = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef.current, crop, "image-cropped.jpeg");
            setUploadedCroppedPhoto(croppedImageUrl);
        }
    };

    const submitUploadedPhoto = async () => {
        const uploadImage = _.cloneDeep(pictureUploaded);
        // setPictureUploaded(null);
        await sendFile({ base64: uploadImage });
    };

    const cancelUploadPhoto = () => {
        setShowPictureConfirmation(false);
        setPictureUploaded(null);
        setModalManageImageOpen(false);
    };

    const confirmPhotoUpload = () => {
        setShowPictureConfirmation(true);
        setModalManageImageOpen(false);
    };

    const resetModalOptions = () => {
        cancelUploadPhoto();
        setPhoto(null);
        setUploadedPhoto(null);
        setImageCropProperties({ aspect: 1 });
        setUploadedCroppedPhoto(null);
        setInputFileKey(Math.random().toString(36));
        setIsLoading(false);
        setIncidenceData({})

        imageRef.current = null;
    };



    return (
        <div className="camera-component">
            <div className="wrapper">
                {showResult ?
                    <ResultScreen {...{ updateIncidence, incidenceData, setPhoto, loadingUpdate, resetModalOptions }} /> :
                    <CameraWrapper
                        {...{ showLoading, camera, photo, }}
                    >
                        <div className="camera-overlay">
                            {pictureUploaded ? null : <div className="upper-options">
                                <CameraTips />
                            </div>}

                            {
                                showPictureConfirmation &&
                                <div className="upper-options">
                                    {!showLoading && <div className="picture-frame-top">
                                        Satisfied with this picture? Click yes to upload, or take another.
                                    </div>}
                                </div>
                            }
                            <div className={`circle-wrapper ${tooDark || tooBright ? "error" : ""}`}>
                                {/* <Lottie options={defaultOptions} height={215} width={215} speed={2} /> */}
                                {pictureUploaded && <img src={pictureUploaded} alt="bug" className="uploaded-pic" />}

                                <div class="overlay-element top-left"></div>
                                <div class="overlay-element top-right"></div>
                                <div class="overlay-element bottom-left"></div>
                                <div class="overlay-element bottom-right"></div>
                            </div>
                            {
                                showPictureConfirmation &&
                                <div className="bottom-options-confirm">
                                    {showLoading ? <div className="searching">
                                        <Lottie options={defaultOptions}
                                            height={20}
                                            width={20} />
                                        <span>
                                            Searching for bugs ...
                                        </span>
                                    </div> :
                                        <div className="picture-frame-bottom">
                                            <div className="reset-pickture"
                                                onClick={() => cancelUploadPhoto()}
                                            >
                                                <AiOutlineReload style={{ marginRight: '10px' }} />
                                                No, take another picture
                                            </div>
                                            <div style={{ width: '100px', marginLeft: '30px' }}>
                                                <Button className="small" onClick={() => { submitUploadedPhoto() }}>Yes</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {pictureUploaded ? null : <div className="footer-options">
                                {tooDark && <div className="error">Too dark</div>}
                                {tooBright && <div className="error">Too bright</div>}
                            </div>}

                            {!showLoading && !pictureUploaded && !bottomSheetOpen &&
                                <div className="bottom-options">
                                    <input key={inputFileKey} type="file" style={{ display: "none" }} id="file-input" onChange={(event) => handleFileUpload(event)} />
                                    <label htmlFor="file-input">
                                        <div className="gallery">
                                            <IoMdPhotos size="34px" color="#fff" />
                                            <div>Upload</div>
                                        </div>
                                    </label>
                                    <div
                                        className="takePicture"
                                        onClick={() => {
                                            handleTakePhoto();
                                        }}
                                    ></div>
                                    <div className="redirect-pest-pros" onClick={() => history.push("/pest-controllers")}>
                                        <AiOutlineUnorderedList size="34px" color="#fff" />
                                        <div>Pest Pros</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </CameraWrapper>
                }
            </div>

            <Modal isOpen={modalManageImageOpen} onRequestClose={() => resetModalOptions()} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
                <div className="upload-image-component">
                    <div className="modal-header">
                        <h3>
                            <strong>Upload image</strong>
                        </h3>

                        <div className="close" onClick={() => resetModalOptions()}>
                            <IoIosClose />
                        </div>
                    </div>

                    <div className="modal-content">
                        <p>Drag to highlight the bug inside the selected image:</p>

                        {uploadedPhoto && (
                            <div className="react-crop-wrapper">
                                <ReactCrop
                                    src={uploadedPhoto}
                                    crop={imageCropProperties}
                                    ruleOfThirds
                                    className="react-crop"
                                    onImageLoaded={onImageLoaded}
                                    onComplete={(crop) => onCropComplete(crop)}
                                    onChange={(crop) => setImageCropProperties(crop)}
                                />
                                {tooSmallCrop && uploadedCroppedPhoto && (
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>Too small crop, select a biggest area</p>
                                )}
                                {loading && <div className="crop-overlay"></div>}
                            </div>
                        )}
                    </div>

                    <div className="modal-bottom">
                        <div className="flex">
                            <Button loading={loading} onClick={() => confirmPhotoUpload()} disabled={tooSmallCrop || !uploadedCroppedPhoto}>
                                Select crop
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <BottomSheet
                open={bottomSheetOpen}
                onDismiss={() => setBottomSheetOpen(false)}
            >
                <div className="bottom-sheet-wrapper">
                    <div className="title" style={{ borderBottom: '1px solid #00000020' }}>
                        Don't miss out, donwload our app
                    </div>
                    <div className="buttons-wrapper">
                        <div className="bottom-sheet-options">
                            <img src={require("assets/icon.png")} alt="begbugid" className="icon" />
                            <div className="content">
                                <div className="title">BedBugID App</div>
                                <Button
                                    style={{ backgroundColor: '#5391f6' }}
                                    anchor="https://onelink.to/bedbugid"
                                    onClick={() => Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.DOWNLOAD_APP)}
                                >Install</Button>
                            </div>
                        </div>
                        <div className="bottom-sheet-options">
                            <div className="content">
                                <div className="title">Continue in the webapp</div>
                                <Button className="custon-button" onClick={() => {
                                    setBottomSheetOpen(false);
                                    Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.CONTINUE_IN_WEBAPP);
                                }}>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>

            <Modal
                className="location-permission-modal"
                overlayClassName="location-permission-modal-overlay"
                isOpen={showModal && !bottomSheetOpen}
                onRequestClose={() => { }}
                contentLabel="Example Modal"
                ariaHideApp={false}>
                <div className=" location-modal center flexColumn ">
                    <h3>Location Permission</h3>
                    {enterZipCode ? (
                        <>
                            <p className="bottom10" style={{ textAlign: "center" }}>
                                Please, enter your Zip Code
                            </p>
                            <input placeholder="Your zip code" type="text" onChange={(e) => setZipCode(e.target.value)} />
                            <Button loading={loading} onClick={() => verifyZip()}>Confirm</Button>
                        </>
                    ) : (
                        <>
                            <p className="bottom10" style={{ textAlign: "center" }}>
                                We need access to your location to recommend the nearby pest pros and for statistics purposes.
                            </p>
                            <Button onClick={() => getUserLocation()}>Allow permission</Button>
                            <p className="marginVertical">or</p>
                            <Button type="secondary" onClick={() => closeModalShowZipCode()}>
                                Enter Zip Code
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        </div >
    );
};

const CameraWrapper = (props) => {
    const { showLoading, children, photo, camera } = props;
    if (showLoading) return (
        <div className="photo-preview">
            {children}
        </div>
    )
    return (
        <div className="camera-fullscreen">
            <Camera ref={camera} aspectRatio="cover" facingMode="environment" />
            {children}
        </div>
    )
}


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const customStyles = {
    content: {
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        padding: 0,
        boxSizing: "border-box",
        border: 0,
    },
};

export default CameraComponent;
