import React from "react";
import { Button } from "components/elements";
import ModalLayout from "../Modal/Modal";
import history from "services/history";
import './RecommendedExterminatorModal.scss';
import { callTerminix } from "services/utils";
import { actions, EVENT_TYPES } from "components/config/mixpanel";

const RecommendedExterminatorModal = (props) => {
    const { incidenceData } = props;

    return (
        <ModalLayout {...props} >
            <div className="recommended-modal-wrapper">
                <div className="title">
                    Connect with our most trusted local vendor
                </div>
                <img src={require('assets/images/terminix.png')} alt="Terminix" />
                <div className="subtitle" >
                    Click below to schedule a free inspection with Terminix.
                </div>
                <div style={{ width: '100%', }}>
                    <Button
                        anchor={"https://www.terminix.com/free-inspection/?interestarea=BED%20BUG&utm_source=bed-bug-id&utm_medium=organic"}
                        className="terminixButton"
                        onClick={() => callTerminix()}
                    >
                        Schedule a Free Inspection
                    </Button>
                </div>
                <Button
                    onClick={() => {
                        history.push({
                            pathname: "/new-job",
                            state: { incidenceData: incidenceData },
                        });
                        actions.sendEvent(EVENT_TYPES.SEE_OTHER_PEST_MANAGERS);

                    }}>
                    See other pest managers
                </Button>
            </div>
        </ModalLayout >
    );
};

export default RecommendedExterminatorModal