import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";
import { Button } from "components/elements";
import * as Mixpanel from 'components/config/mixpanel';
import "swiper/swiper.scss";
import "./tutorial.scss";

const TutorialComponent = (props) => {
    const [swiper, setSwiper] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        Mixpanel.actions.pageView('TUTORIAL')
    }, [])

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    return (
        <div className="tutorial-screen">
            <Swiper ref={ref}>
                <div className="swiper-slide">
                    <div className="mockup">
                        <div className="gradient"></div>
                        <img src={require("assets/tutorial1.png")} alt="" />
                    </div>
                    <div className="details">
                        <h3>Identify Bed Bugs by Taking a Picture</h3>
                        <p>
                            BugID allows you to identify whether you have a bedbug or not with the click of a button.
                        </p>
                        <Button onClick={goNext}>{ref.current?.swiper?.isEnd ? "Continue" : "Next"}</Button>
                    </div>
                </div>
                <div className="swiper-slide">
                    <div className="mockup">
                        <div className="gradient"></div>
                        <img src={require("assets/tutorial3.png")} alt="" />
                    </div>
                    <div className="details">
                        <h3>Highlight the bug</h3>
                        <p>
                            Drag square to highlight the bug inside the selected image.
                        </p>
                        <Button onClick={goNext}>{ref.current?.swiper?.isEnd ? "Continue" : "Next"}</Button>
                    </div>
                </div>
                <div className="swiper-slide">
                    <div className="mockup">
                        <div className="gradient"></div>
                        <img src={require("assets/tutorial2.png")} alt="" />
                    </div>
                    <div className="details">
                        <h3>Contact a Pest Pro</h3>
                        <p>We make it easy for you to find a professional pest management company in your area. Just browse by area or pest and click Contact.</p>
                        <Button onClick={goNext}>{ref.current?.swiper?.isEnd ? "Continue" : "Next"}</Button>
                    </div>
                </div>
                <div className="swiper-slide">
                    <div className="mockup">
                        <div className="gradient"></div>
                        <img src={require("assets/tutorial4.png")} alt="" />
                    </div>
                    <div className="details">
                        <h3>Give us some feedback</h3>
                        <p>This app is still in beta. Your suggestions are really important for the future of this app.</p>
                        <Button link="/camera">Continue</Button>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};
export default TutorialComponent;
