import React, { useState, useEffect } from "react";
import { Header, Search, Button } from "components/elements";
import Lottie from 'react-lottie';
import * as animationData from "assets/lottie/scs2.json";

import "./Feedback.scss";

const FeedbackComponent = (props) => {
    const { submit, loading, wasSent } = props;
    const [text, setText] = useState("");

    return (
        <div className="feedback-conponent">

            <Header />
            <div className="content">
                <h2>Feedback</h2>

                <p className='subtitle'>Please let us know your thoughts. Leave us any suggestions you have.</p>

                {wasSent ?
                    <div className="success">
                        <Lottie options={defaultOptions} style={{ margin: 0 }} height={30} width={30} isStopped={false} isPaused={false} />
                        <h3>Success!</h3>
                        <p>Thank you for your feedback!</p>
                    </div> :
                    <>
                        <textarea onChange={(e) => setText(e.target.value)} className='comments-input' placeholder="Leave your comments here!" type="text" />

                        <Button loading={loading} onClick={() => submit(text)} disabled={!text}>Send</Button>
                    </>
                }
            </div>
        </div>
    );
};

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};


export default FeedbackComponent;
