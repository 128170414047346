import React, { useState, useEffect } from "react";
import FeedbackComponent from "../components/FeedbackComponent";
import { sendFeedbackService } from 'services/feedback';
import * as Mixpanel from 'components/config/mixpanel';
import _ from 'lodash';

const FeedbackContainer = (props) => {
    const [loading, setLoading] = useState(false);
    const [wasSent, setWasSent] = useState(false)

    const submit = async (text) => {
        try {
            Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.SEND_FEEDBACK)
            setLoading(true);
            const res = await sendFeedbackService({ message: text });
            console.log('res', res);
            setWasSent(true);
        }
        catch (e) {
            console.log('error setting preferences', e);
        }
        finally {
            setLoading(false);
        }
    }


    return <FeedbackComponent
        submit={submit}
        loading={loading}
        wasSent={wasSent}
    />;
};

export default FeedbackContainer;
