import React, { useRef, useEffect, useState } from 'react'
import { AiFillBug, AiOutlineZoomIn, AiOutlineBulb } from 'react-icons/ai';

const duration = 3000;
const animationTime = 1500;

const CameraTips = (props) => {
    // const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    const [currentTip, setCurrentTip] = useState(0)
    const timer = useRef(null)  // Initial value for opacity: 0

    useEffect(() => {

        startAnimation();
        return () => clearTimeout(timer.current);
    }, [currentTip]);

    const startAnimation = () => {


        timer.current = setTimeout(() => {

            setCurrentTip((currentTip + 1) % 3)

        }, duration + animationTime);
        return timer;
    }

    return (
        <div
            style={props.style}
            className="camera-tips"
        >
            {currentTip === 0 ?
                <Tip title={"Squash or Snap?"} icon={<AiFillBug />} text="Do not squash the bug before taking a pic." /> : null
            }
            {currentTip === 1 ?
                <Tip title={"Zoom"} icon={<AiOutlineZoomIn />} text="Get closer to the bug." /> : null
            }
            {currentTip === 2 ?
                <Tip title={"Light"} icon={<AiOutlineBulb />} text="Make sure you have a contrasted background." /> : null
            }

        </div>
    )
}

const Tip = (props) => {
    const { text, icon, title } = props;
    return (
        <div className="tip">
            {/* <Image source={icon} resizeMode='contain' style={{ width: 40, height: 40 }} resizeMode='contain' /> */}
            {icon}
            <span className="title" style={{ marginTop: 10 }}>{title}</span>
            <span center light>{text}</span>
        </div>
    )
}

const styles = {
    wrapper: {
    },
    tip: {
        alignItems: 'center',
        maxWidth: 200
    }
}
export default CameraTips;