import React from "react";
import { Button, Header } from "components/elements";
import './EmailToEntomologist.scss';
import { RiDownloadLine } from 'react-icons/ri'

const EmailToEntomologistComponent = (props) => {
    const {
        loadingUpdate,
    } = props;

    return (
        <div className="email-to-entomologist">
            <Header />
            <div className="content">
                <div className="app-icon-wrapper">
                    <img src={require("assets/icons/app-icon.png")} alt="" />
                    <p className="appname">BedBug ID</p>
                </div>

                <p className="title">Continue in BedBugID App</p>

                <p className="description">Donwload the App to get your picture reviewed by an entomologist and chat in real time with them.</p>

                <Button
                    anchor="https://onelink.to/bedbugid"
                    disabled={loadingUpdate === "done"}
                    className="button"
                    icon={<RiDownloadLine />}
                    loading={loadingUpdate === true}>
                    Download
                </Button>
            </div>
        </div>
    );
};

export default EmailToEntomologistComponent;
