import endpoints from "./endpoints";
import customFetch from "./customFetch";
import Jimp from "jimp";

export const createIncidenceService = async (data, { file, dataUri, base64 }) => {
    let fileBase64;
    if (file) {
        fileBase64 = await fileToBase64(file);
    } else {
        fileBase64 = base64;
    }

    data = {
        lat: data.lat,
        long: data.long,
        urlImage: fileBase64,
        os: `webapp-${window.navigator.platform}`,
        origin: "webapp",
    };

    try {
        let response = await customFetch(endpoints.incidences, {
            method: "POST",
            bodyReq: data,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const predictService = async (fileBlob) => {
    try {
        var formdata = new FormData();
        formdata.append("image", fileBlob, "[PROXY]");
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        let response = await fetch("https://cors-anywhere.herokuapp.com/http://beta-env.eba-ypmnysfp.us-east-1.elasticbeanstalk.com/predict", requestOptions);

        response = await response.text();
        return response;
    } catch (err) {
        throw err;
    }
};

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
}

function fileToBase64(blob) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64data = reader.result;
            // base64data = base64data.replace(/jpeg/, "jpg");
            resolve(base64data);
        };
        reader.onerror = function (e) {
            reject(reader.error);
        };
    });
}

export function getImageLightness(imageSrc, callback) {
    var img = document.createElement("img");
    img.src = imageSrc;
    img.style.display = "none";
    document.body.appendChild(img);

    var colorSum = 0;

    img.onload = function () {
        // create canvas
        var canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0);

        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var data = imageData.data;
        var r, g, b, avg;

        for (var x = 0, len = data.length; x < len; x += 4) {
            r = data[x];
            g = data[x + 1];
            b = data[x + 2];

            avg = Math.floor((r + g + b) / 3);
            colorSum += avg;
        }

        var brightness = Math.floor(colorSum / (this.width * this.height));

        callback(brightness);
    };
}

export function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
            resolved({ imageWidth: i.width, imageHeight: i.height });
        };
        i.src = file;
    });
}

export async function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    const base64Image = canvas.toDataURL("image/jpeg");

    const imageJimp = await Jimp.read(base64Image);
    const imageResizedTo200 = imageJimp.resize(250, 250);

    return new Promise((resolve, reject) => {
        // resolve(base64Image);
        imageResizedTo200.getBase64(Jimp.AUTO, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

export const updateIncidenceService = async (id, data) => {

    try {
        let response = await customFetch(`${endpoints.incidences}/${id}`, {
            method: "PATCH",
            bodyReq: data,
        });

        if (response.error) throw response;

        return { ...response };
    } catch (err) {
        throw err;
    }
};