import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';
import animationData from 'assets/lottie/loading-white.json'
import _ from 'lodash';
import "./button.scss";

const ButtonComponent = (props) => {
    const { children } = props;

    return <ClickHandler {...props}>{children}</ClickHandler>;
};

const ClickHandler = (props) => {
    const { children, type, className, link, anchor, loading, disabled, onClick, terminix, secondary, style, icon, params } = props;
    let newStyle = style ? _.cloneDeep(style) : {};
    const isDisabled = disabled || loading;
    newStyle.opacity = isDisabled ? 0.7 : 1;
    
    if (link) {
        return (
            <Link to={link} params={params} className={`button-component ${type || ''} ${className || ''} ${terminix || ''} ${secondary && 'secondary'}`}>
                {children}
            </Link>
        );
    } else if (anchor) {
        return (
            <a href={anchor} onClick={onClick}
                className={`button-component ${type || ''} ${className || ''} ${terminix || ''} ${secondary && 'secondary'} ${icon && 'with-icon'} ${isDisabled}`}
                style={newStyle}
            >
                <div className="children-wrapper">
                    {icon ? <div className="icon-wrapper">
                        {icon}
                    </div> : null}
                    {children}
                </div>
            </a>
        );
    } else {
        return (
            <div
                type={type || 'button'}
                disabled={isDisabled}
                style={newStyle}
                className={`button-component ${type || ''} ${className || ''} ${terminix || ''} ${secondary && 'secondary'} ${icon && 'with-icon'} ${isDisabled}`}
                onClick={!isDisabled && onClick}>
                {
                    loading ?
                        <Lottie options={defaultOptions}
                            height={20}
                            width={20} /> :
                        <div className="children-wrapper">
                            {icon ? <div className="icon-wrapper">
                                {icon}
                            </div> : null}
                            {children}
                        </div>
                }
            </div>
        );
    }
};

export default ButtonComponent;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};