import React, { useState, useEffect } from "react";
import { Header, Search } from "components/elements/index";
import PestCard from "./PestCard";
import Filters from "components/Filter/containers/Filter";
import _ from "lodash";
import { IoIosClose } from "react-icons/io";
import * as Mixpanel from 'components/config/mixpanel';
import "./pestController.scss";

const PestControllerComponent = (props) => {
    const { pestControllers, applyFilters, filters, removeFilterItem } = props;
    const filterKeys = _.keys(filters);

    const [search, setSearch] = useState("");
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        Mixpanel.actions.pageView("SEARCH");
    }, [])

    const filterBySearch = (pestController) => {
        if (_.isEmpty(pestController)) return false;
        if (!search) return true;
        if (pestController.name?.toLowerCase().includes(search.toLowerCase())) return true;
        else if (pestController.lastName?.toLowerCase().includes(search.toLowerCase())) return true;
        else if (pestController.postalCode?.toLowerCase().includes(search.toLowerCase())) return true;
        else if (pestController.country?.toLowerCase().includes(search.toLowerCase())) return true;
        else if (pestController.city?.toLowerCase().includes(search.toLowerCase())) return true;
        return false;
    };

    return (
        <div className="pest-controllers-conponent">
            <Filters
                filters={filters}
                applyFilters={applyFilters}
                showModal={showFilters}
                toggleModal={() => {
                    Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.OPEN_FILTER);
                    setShowFilters(!showFilters)
                }} />
            <Header />
            <div className="content">
                <h2>Pest Pros</h2>

                <Search
                    placeholder="Search..."
                    value={search}
                    onFilterClick={() => setShowFilters(true)}
                    onChange={(e) => {
                        Mixpanel.actions.sendEvent(Mixpanel.EVENT_TYPES.SEARCH, { text: e.target.value });
                        setSearch(e.target.value);
                    }}
                />

                <div className="filter-items">
                    {
                        filterKeys.map((filterKey, i) => {
                            let showFilter = false;

                            // Checkboxes filter
                            if (Array.isArray(filters[filterKey]?.filterValue && filters[filterKey]?.filterValue?.length)) showFilter = true;

                            // Select pickers filter
                            if (typeof filters[filterKey]?.filterValue === "object" && !_.isEmpty(filters[filterKey]?.filterValue)) showFilter = true;

                            // Orderby filter
                            if (typeof filters[filterKey]?.filterValue === "string" && filters[filterKey]?.filterValue) showFilter = true;

                            if (showFilter) {
                                if (Array.isArray(filters[filterKey].filterValue)) {
                                    const arrayItems = filters[filterKey].filterValue;

                                    return arrayItems.map((arrayItem, i) => (
                                        <FilterTag
                                            key={i}
                                            onClick={() => removeFilterItem(filterKey, arrayItem)}
                                            tagKey={filters[filterKey].filterLabel}
                                            tagValue={arrayItem}
                                        />
                                    ));
                                } else {
                                    return (
                                        <FilterTag
                                            key={i}
                                            onClick={() => removeFilterItem(filterKey, filters[filterKey])}
                                            tagKey={filters[filterKey].filterLabel}
                                            tagValue={typeof filters[filterKey]?.filterValue === "object" ? filters[filterKey]?.filterValue?.label : filters[filterKey].filterValue}
                                        />
                                    );
                                }
                            } else {
                                return null;
                            }
                        })
                    }
                </div>

                {pestControllers.filter(filterBySearch).map((pest, i) => {
                    return <PestCard pestController={pest} key={i} />;
                })}
            </div>
        </div>
    );
};

const FilterTag = (props) => {
    const { tagKey, tagValue, onClick } = props;

    return (
        <div className="filter-tag" onClick={onClick}>
            <div className="filter-tag-close">
                <IoIosClose />
            </div>
            <div className="filter-tag-name">{tagKey}: </div>
            <div className="filter-tag-value">{tagValue}</div>
        </div>
    );
};


export default PestControllerComponent;
