import endpoints from "./endpoints";
import customFetch from "./customFetch";
import _ from "lodash";

export const getPestControllersService = async (filters) => {
    let filtersFormatted = {
        where: {
            and: [],
        },
    };

    _.keys(filters).forEach(filterKey => {
        const filterValue = filters[filterKey].filterValue;

        if (filterKey !== 'orderBy') {
            if (!_.isEmpty(filterValue) && filterValue) {
                if (Array.isArray(filterValue)) {
                    filterValue.forEach(filterValueItem => {
                        filtersFormatted.where.and.push({
                            [filterKey]: filterValueItem
                        })
                    })
                }
                else {
                    filtersFormatted.where.and.push({
                        [filterKey]: typeof filterValue === 'object' ? filterValue.label : filterValue
                    });
                }
            }
        }
    })

    let url = endpoints.pestControllers;

    if (filtersFormatted.where.and.length) {
        url = `${url}?filter=${JSON.stringify(filtersFormatted)}`;
    }

    try {
        let response = await customFetch(url, {
            method: "GET",
        });

        if (response.error) throw response;
        return response;
    } catch (err) {
        throw err;
    }
};

export const pestControllerRequestsService = async (data) => {

    try {
        let response = await customFetch(`${endpoints.pestControllerRequests}`, {
            method: "POST",
            bodyReq: data,
        });

        return response
    }
    catch (e) {
        console.log('error updating pestControllerRequestsService', e);
    }
}