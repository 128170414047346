import React, { useState, useEffect } from "react";
import { IoMdWarning } from "react-icons/io";
import "./threat.scss";

const ThreatLevelComponent = (props) => {
    const { risk } = props;

    const getRiskText = () => {
        switch (risk) {
            case "low":
                return "Low threat";

            case "medium":
                return "Medium threat";

            case "high":
                return "High threat";

            case "na":
                return "Not available";

            default:
                return "Medium threat";
        }
    };

    return (
        <div className={`threat-component ${risk}`}>
            <IoMdWarning size="18px" color="#fff" style={{ marginRight: 7 }} />
            {getRiskText()}
        </div>
    );
};
export default ThreatLevelComponent;
