import mixpanel from 'mixpanel-browser';
mixpanel.init('169af468d9b973952fdc7f09548cae2a');

let env_check = true;

export const EVENT_TYPES = {
    "CONTACT": "CONTACT",
    "TAKE_PICTURE": "TAKE_PICTURE",
    "CALL_TERMINIX": "CALL_TERMINIX",
    "UPLOAD_PICTURE": "UPLOAD_PICTURE",
    "OPEN_BURGER": "OPEN_BURGER",
    "OPEN_FILTER": "OPEN_FILTER",
    "SEND_FEEDBACK": "SEND_FEEDBACK",
    "PREVENTIVE_STEPS": "PREVENTIVE_STEPS",
    "SEARCH": "SEARCH",
    "INSERT_ZIPCODE": "INSERT_ZIPCODE",
    "ALLOW_LOCATION": "ALLOW_LOCATION",
    "SUBMIT_EMAIL_TO_ENTOMOLOGIST": "SUBMIT_EMAIL_TO_ENTOMOLOGIST",
    "FIND_A_PEST_PRO_IN_YOUR_AREA": "FIND_A_PEST_PRO_IN_YOUR_AREA",
    "SUBMIT_TO_ENTOMOLOGIST": "SUBMIT_TO_ENTOMOLOGIST",
    "PEST_CONTROLLER_DETAILS": "PEST_CONTROLLER_DETAILS",
    "NEW_JOB_PEST_CONTROLLERS_RESULTS": "NEW_JOB_PEST_CONTROLLERS_RESULTS",
    "SEE_OTHER_PEST_MANAGERS": "SEE_OTHER_PEST_MANAGERS",
    "SUBMIT_NEW_JOB_PAGE1": "SUBMIT_NEW_JOB_PAGE1",
    "SUBMIT_NEW_JOB_PAGE2": "SUBMIT_NEW_JOB_PAGE2",
    "CONTINUE_IN_WEBAPP": "CONTINUE_IN_WEBAPP",
    "DOWNLOAD_APP": "DOWNLOAD_APP",
}

export const actions = {
    //   identify: (id) => {
    //     if (env_check) mixpanel.identify(id);
    //   },
    //   alias: (id) => {
    //     if (env_check) mixpanel.alias(id);
    //   },
    sendEvent: (event, data) => {
        if (env_check) {
            mixpanel.track(event, { ...data, platform: 'web' });
        }
    },
    pageView: (screen, data) => {
        if (env_check) {
            console.log('send page view');
            mixpanel.track("PAGE_VIEW", { screen, ...data, platform: 'web' });}
    },
    //   people: {
    //     set: (props) => {
    //       if (env_check) mixpanel.people.set(props);
    //     },
    //   },
};
