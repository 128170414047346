import React from "react";
import { FaCheck } from "react-icons/fa";
import './CheckboxRow.scss';

const CheckboxRow = (props) => {
    const { label, onClick, isChecked } = props;

    return (
        <div className="checkbox-row" onClick={onClick}>
            <div>{label}</div>
            <div className={isChecked ? "checkbox checkbox-checked" : "checkbox"}>
                {
                    isChecked ?
                        <FaCheck /> :
                        null
                }
            </div>
        </div>
    );
};

export default CheckboxRow;